// src/app.js
import './App.css';
import { ethers } from 'ethers';
import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { doc, setDoc, getDoc, updateDoc, getFirestore } from "firebase/firestore"; 
import FactoryArtifact from "./artifacts/contracts/GenericERC1155Factory.sol/GenericERC1155Factory.json";
import GenericToken from './artifacts/contracts/GenericERC1155.sol/GenericERC1155.json';
const tokenFactoryAddress = "0x9ea41a357254FE46a6871598560b022aa1ba0715";
const { MerkleTree } = require('merkletreejs');

const keccak256 = require('keccak256');

const firebaseConfig = {
  apiKey: "AIzaSyAP6mCMg_764qetRgNJ6t5qKvuzkaj19DI",
  authDomain: "airdroppr-48b04.firebaseapp.com",
  projectId: "airdroppr-48b04",
  storageBucket: "airdroppr-48b04.appspot.com",
  messagingSenderId: "535639232576",
  appId: "1:535639232576:web:5e766b7e7edb747b90cfa5",
  measurementId: "G-QBKDP5KG58"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);




function App() {
 const [tokenData, setTokenData] = useState({})
  const [name, setName] = useState()
  const [tokenUri, setTokenUri] = useState()
  const [tokenSupply, setTokenSupply] = useState()
  const [drop, setDrop] = useState()
  const [dropInfo, setDropInfo] = useState()
  const [airdropAddresses, setAirdropAddresses] = useState()
  const [merkleTree, setMerkleTree] = useState()

  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  
  useEffect(() => {
    const drop = window.location.pathname.split('/')[1];
    setDrop(drop)
    async function getDropInfo() {
      const docRef = doc(db, "erc1155drops", drop);
      const docSnap = await getDoc(docRef);
      console.log('documents', docSnap.data())
      setDropInfo(docSnap.data())
    }
    getDropInfo()
  }, [])
async function requestAccount() {
  
  await window.ethereum.request({ method: 'eth_requestAccounts' });
}

 async function initializeFactoryContract(init) {
 
  const factoryContract = new ethers.Contract(
    tokenFactoryAddress,
    FactoryArtifact.abi,
    init
  );

  return factoryContract
 }
 async function initializeTokenContract(init, tokenContractAddress) {
  const tokenContract = new ethers.Contract(
    tokenContractAddress,
    GenericToken.abi,
    init
  );

  return tokenContract
  }
  

  async function _getTokenData() {
    await requestAccount()  
    const contract = await initializeFactoryContract(signer)
    // const airdropI = await contract.airdrops(2)
    // console.log('adrops', airdropI)
    const contracta = await contract.deployedCollections(1)
    console.log('v', contracta);
  const tokenData = {name: 's', symbol: 's'}
  setTokenData(tokenData);
  }

  function hashToken(account) {
    console.log('asdfsfa', account)
    return Buffer.from(ethers.utils.solidityKeccak256(['address'], [account]).slice(2), 'hex')
  }
  
  async function mintToken() {
    // @todo: allow diff mint length
    const airdropAddressList = airdropAddresses.split(",")
    console.log('airdrop list is', airdropAddressList)
    const adl = airdropAddressList.map(s => s.toString())
    await setMerkleTree(new MerkleTree(adl.map(account => hashToken(account)), keccak256, { sortPairs: true }));
    const mt = merkleTree.getHexRoot();
    console.log('mt', mt, merkleTree);
    if (typeof window.ethereum !== 'undefined') {
      await requestAccount()
      const contract = await initializeFactoryContract(signer)

      const blah = await contract.createGenericERC1155(name, mt, tokenUri, 100);
      console.log('blah is', contract);
      const numContracts = await contract.deployedCollectionsCount();
      console.log('num contracts', numContracts)
      console.log('num contracts is', numContracts.toString())
      // @todo: will not work with name conflicts
      await setDoc(doc(db, "erc1155drops", name), {
        name,
        airdropAddressList,
        tokenUri,
        contractIndex: numContracts.toString()
      });
      console.log('blah', blah);
      alert(`good job. now send users to https://lfgairdrop.com/${name} to claim their token`)
    }
  }
  async function claimReward() {
    if (typeof window.ethereum !== 'undefined') {
      await requestAccount()
      let dropContractAddress = dropInfo.contractAddress;
      if (!dropContractAddress) {
        console.log('no address')
        const factoryContract = await initializeFactoryContract(signer)
        console.log('fc', factoryContract)
        const deployedIndex = parseInt(dropInfo.contractIndex) + 1
        console.log("EDPLOYED INDEX", deployedIndex)
        const deployedContract = await factoryContract.deployedCollections(deployedIndex);
        dropContractAddress = deployedContract.contractAddress;
        console.log('drop address', dropContractAddress);
        updateDoc(doc(db, "erc1155drops", dropInfo.name), {
          contractAddress: deployedContract.contractAddress
        });
      }
    

      const account = await signer.getAddress();
      console.log('acc', account);
      const allowedAddresses = dropInfo.airdropAddressList;
      const merkleTree = new MerkleTree(allowedAddresses.map(account => hashToken(account)), keccak256, { sortPairs: true })
      console.log('merkle tree', merkleTree)
      const proof = merkleTree.getHexProof(hashToken(account));
      console.log('address is,', dropContractAddress)
      const contract = await initializeTokenContract(signer, dropContractAddress)
      // @todo update because now always index 1 so unnecessary param
      try {
        await contract.award(1, account, proof);
        alert(`token successfully claimed. now figure out how to do anything with it. you can check out the contract at ${dropInfo.contractAddress || dropContractAddress} and view metadata at ${dropInfo.tokenUri}`)
      }
      catch{
        alert('you are not eligible. go away.')
      }
       
      
    }
  
}
  
  return (
      dropInfo ? <div className="App">
      <header className="App-header">
        <h1>{dropInfo.name}</h1>
        {/* <h3>token uri: {dropInfo.tokenUri}</h3>
        {dropInfo.contractAddress && <p>contract address: {dropInfo.contractAddress}</p>} */}
        <button style={{ width: 500, marginTop: 100,  boxShadow: "10px 15px", height: 50, fontFamily: 'Roboto Mono'}} onClick={claimReward}>Claim Reward</button>
      </header>
    </div> :
      <div className="App">
      <header className="App-header">
          <h1 style={{color: '#39ff14'}}>Make your airdrop</h1>
          <input style={ {width: 500, marginBottom: 20, height: 50, fontFamily: 'Roboto Mono', borderRadius: 5, paddingLeft: 10 }} onChange={e => setAirdropAddresses(e.target.value)} placeholder="Airdrop Addresses" />
          <input style={ {width: 500, marginBottom: 20, height: 50, fontFamily: 'Roboto Mono', borderRadius: 5, paddingLeft: 10 }} onChange={e => setName(e.target.value)} placeholder="Name" />
          <input style={ {width: 500, marginBottom: 20, height: 50, fontFamily: 'Roboto Mono', borderRadius: 5, paddingLeft: 10 }} onChange={e => setTokenUri(e.target.value)} placeholder="Token Uri" />
          <input style={ {width: 500, marginBottom: 20, height: 50, fontFamily: 'Roboto Mono', borderRadius: 5, paddingLeft: 10 }} onChange={e => setTokenSupply(e.target.value)} placeholder="Token Supply" />
          <button style={{ width: 500, marginTop: 100,  boxShadow: "10px 15px", height: 50, fontFamily: 'Roboto Mono'}} onClick={mintToken}>Mint Token</button>
      </header>
    </div>
      
    );
  }
  
export default App;